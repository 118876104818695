"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("../../sources/types");
var icon = ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [(0, jsx_runtime_1.jsx)("rect", { width: "28", height: "28", rx: "4", fill: "#4F44E0" }), (0, jsx_runtime_1.jsx)("circle", { cx: "8", cy: "14", r: "3", fill: "white" }), (0, jsx_runtime_1.jsx)("circle", { cx: "16", cy: "14", r: "2", fill: "white" }), (0, jsx_runtime_1.jsx)("circle", { cx: "22", cy: "14", r: "1", fill: "white" })] })));
var mixpanelDestination = {
    description: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Cience can send events from JS SDK or Events API to Mixpanel Ingestion API filling as much Mixpanel Events Properties as possible from original event data.", (0, jsx_runtime_1.jsx)("br", {}), "Implementation is based on npm-package:", (0, jsx_runtime_1.jsx)("br", {}), "https://www.npmjs.com/package/mixpanel-destination", (0, jsx_runtime_1.jsx)("br", {}), "Source code:", (0, jsx_runtime_1.jsx)("br", {}), "https://github.com/jitsucom/jitsu-mixpanel"] })),
    syncFromSourcesStatus: "not_supported",
    id: "mixpanel",
    type: "other",
    displayName: "Mixpanel",
    deprecated: true,
    deprecatedReplacement: "mixpanel2",
    parameters: [
        {
            id: "_super_type",
            constant: "npm",
        },
        {
            id: "_package",
            constant: "mixpanel-destination",
        },
        {
            id: "_formData.deprecation",
            displayName: "Deprecation Notice",
            type: types_1.descriptionType,
            defaultValue: ((0, jsx_runtime_1.jsx)("span", __assign({ className: "text-warning" }, { children: (0, jsx_runtime_1.jsxs)("b", { children: ["This version is deprecated because newer version is available. Please replace it with ", (0, jsx_runtime_1.jsx)("b", { children: "Mixpanel v2" }), " ", "destination."] }) }))),
        },
        {
            id: "_formData.description",
            displayName: "Description",
            required: false,
            type: types_1.descriptionType,
            defaultValue: ((0, jsx_runtime_1.jsxs)("span", { children: ["Cience sends events to Mixpanel Ingestion API filling as much Mixpanel Events Properties as possible from original event data.", (0, jsx_runtime_1.jsx)("br", {}), "Mixpanel destination may also send User Profiles data to Mixpanel accounts that have User Profiles enabled.", (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), "Implementation is based on ", (0, jsx_runtime_1.jsx)("b", { children: "npm-package:" }), " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://www.npmjs.com/package/mixpanel-destination" }, { children: "mixpanel-destination" }))] })),
        },
        {
            id: "_formData.token",
            displayName: "Project Token",
            required: true,
            type: types_1.stringType,
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://developer.mixpanel.com/reference/project-token" }, { children: "Project Token" })), ". A project's token can be found in the Access Keys section of a project's settings overview page:", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://mixpanel.com/settings/project/" }, { children: "https://mixpanel.com/settings/project/" }))] })),
        },
        {
            id: "_formData.users_enabled",
            displayName: "Enable User Profiles",
            required: false,
            type: types_1.booleanType,
            documentation: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Enables Mixpanel destination to work with User Profiles." })),
        },
        {
            id: "_formData.anonymous_users_enabled",
            displayName: "User Profiles for anonymous users",
            required: false,
            type: types_1.booleanType,
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["Enables updating User Profiles for anonymous users. Requires ", (0, jsx_runtime_1.jsx)("b", { children: "Enable User Profiles" }), " enabled."] })),
        },
    ],
    ui: {
        icon: icon,
        connectCmd: null,
        title: function (cfg) { return "."; },
    },
};
exports.default = mixpanelDestination;
