"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("./common");
var types_1 = require("../../sources/types");
var icon = ((0, jsx_runtime_1.jsxs)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", viewBox: "0 0 9 8" }, { children: [(0, jsx_runtime_1.jsx)("style", { children: ".o{fill:#fc0}.r{fill:red}" }), (0, jsx_runtime_1.jsx)("path", { d: "M0,7 h1 v1 h-1 z", className: "r" }), (0, jsx_runtime_1.jsx)("path", { d: "M0,0 h1 v7 h-1 z", className: "o" }), (0, jsx_runtime_1.jsx)("path", { d: "M2,0 h1 v8 h-1 z", className: "o" }), (0, jsx_runtime_1.jsx)("path", { d: "M4,0 h1 v8 h-1 z", className: "o" }), (0, jsx_runtime_1.jsx)("path", { d: "M6,0 h1 v8 h-1 z", className: "o" }), (0, jsx_runtime_1.jsx)("path", { d: "M8,3.25 h1 v1.5 h-1 z", className: "o" })] })));
var destination = {
    description: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["ClickHouse is a fast and scalable database developed by Yandex. ClickHouse is not easy to mainatain, however the performance is remarkable. Managed services can be obtained from", " ", (0, jsx_runtime_1.jsx)("a", __assign({ target: "_blank", href: "https://altinity.com/cloud-database/" }, { children: "Altinity.Cloud" }))] })),
    syncFromSourcesStatus: "supported",
    id: "clickhouse",
    type: "database",
    displayName: "ClickHouse",
    ui: {
        icon: icon,
        title: function (cfg) { var _a, _b; return (((_b = (_a = cfg === null || cfg === void 0 ? void 0 : cfg._formData) === null || _a === void 0 ? void 0 : _a.ch_dsns_list) === null || _b === void 0 ? void 0 : _b.length) ? cfg._formData.ch_dsns_list[0] : "Unknown"); },
        connectCmd: function (cfg) {
            var _a, _b;
            return ((_b = (_a = cfg === null || cfg === void 0 ? void 0 : cfg._formData) === null || _a === void 0 ? void 0 : _a.ch_dsns_list) === null || _b === void 0 ? void 0 : _b.length)
                ? "echo 'SELECT 1' | curl '".concat(cfg._formData.ch_dsns_list[0], "' --data-binary @-")
                : "";
        },
    },
    parameters: [
        (0, common_1.modeParameter)(),
        (0, common_1.tableName)(),
        {
            id: "_formData.ch_dsns_list",
            displayName: "Datasources",
            required: true,
            type: (0, types_1.arrayOf)(types_1.stringType),
            documentation: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "A list of DSNs (server names). It's recommended to add at least two servers within the cluster for redundancy" })),
        },
        {
            id: "_formData.ch_cluster",
            displayName: "Cluster",
            required: function (cfg) { var _a, _b; return ((_b = (_a = cfg._formData) === null || _a === void 0 ? void 0 : _a.ch_dsns_list) === null || _b === void 0 ? void 0 : _b.length) > 1; },
            type: types_1.stringType,
            documentation: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("p", { children: "Cluster name." }), (0, jsx_runtime_1.jsxs)("p", { children: ["Run ", (0, jsx_runtime_1.jsx)("code", { children: "SELECT * from system.clusters" }), " to the list of all available clusters"] })] })),
        },
        {
            id: "_formData.ch_database",
            displayName: "Database",
            documentation: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Database name." })),
            required: true,
            type: types_1.stringType,
        },
        {
            id: "_users_recognition._enabled",
            displayName: "User Recognition",
            documentation: ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: "Cience can retroactively update events from anonymous users with user id after users identification. User Recognition support for Clickhouse is limited to ReplacingMergeTree and ReplicatedReplacingMergeTree engine. Clickhouse handles data mutation differently." })),
            required: false,
            defaultValue: true,
            type: types_1.booleanType,
        },
    ],
};
exports.default = destination;
